import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import ConfirmationDialog from "@/common/components/Dialog/ConfirmationDialog";
import { getConversion } from "@/common/lib/api";
import { errorHandler } from "@/common/lib/error-handler";
import PaymentDialog from "@/common/components/Dialog/PaymentDialog";
import { queryGeneticAnalysisOrderById, queryGeneticDataById, queryGeneticAnalysisByGeneticAnalysisTrackingId, cancelGeneticAnalysisOrder, cancelGeneticAnalysisOrderFee } from "@debionetwork/polkadot-provider";
import { setGeneticAnalysisPaid } from "@/common/lib/polkadot-provider/command/genetic-analysis-orders";
import { formatUSDTE } from "@/common/lib/price-format.js";
import Web3 from "web3";
export default {
  name: "PaymentCard",
  components: {
    ConfirmationDialog,
    PaymentDialog
  },
  data: () => ({
    rate: null,
    isDeficit: false,
    txWeight: 0,
    orderId: null,
    geneticOrderDetail: null,
    createdDate: null,
    orderStatus: null,
    orderPrice: null,
    qcPrice: null,
    totalPrice: null,
    orderCurrency: null,
    totalPriceInUsd: null,
    showCancelDialog: false,
    isLoading: false,
    isCancelling: false,
    trackingId: null,
    isRegistered: true,
    newFile: null,
    geneticLink: null,
    links: [],
    geneticData: {},
    customerBoxPublicKey: null,
    error: null,
    balance: 0,
    formatUSDTE
  }),
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet,
      walletBalance: state => state.substrate.walletBalance,
      usdtBalance: state => state.substrate.usdtBalance,
      lastEventData: state => state.substrate.lastEventData
    }),
    setStyleColor() {
      if (this.isDeficit) {
        return "color: #F5222D";
      }
      return "color: #363636";
    },
    setStatusColor() {
      if (this.orderStatus === "Paid") {
        return "color: #5640A5";
      }
      if (this.orderStatus === "Cancelled") {
        return "color: #9B1B37";
      }
      return "color: #363636";
    }
  },
  watch: {
    async lastEventData(e) {
      if (e !== null) {
        const dataEvent = JSON.parse(e.data.toString());
        if (e.method === "GeneticAnalysisOrderPaid") {
          if (dataEvent[0].customerId === this.wallet.address) {
            this.isLoading = false;
            this.$router.push({
              name: "customer-request-analysis-success",
              params: {
                id: this.orderId
              }
            });
          }
        }
        if (e.method === "GeneticAnalysisOrderCancelled") {
          if (dataEvent[0].customerId === this.wallet.address) {
            this.isCancelling = false;
            this.showCancelDialog = false;
            this.$router.push({
              name: "customer-request-analysis-success",
              params: {
                id: this.orderId
              }
            });
          }
        }
        await this.getBalance();
      }
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      this.isCreated = true;
      this.orderId = this.$route.params.id;
      await this.getGeneticAnalysisOrderDetail();
      await this.getGeneticData();
      await this.getAnalysisStatus();
      await this.getBalance();
    }
    if (Number(this.balance) <= Number(this.totalPrice.replaceAll(",", ""))) {
      this.isDeficit = true;
    }
  },
  methods: {
    async getBalance() {
      this.balance = this.orderCurrency === "DBIO" ? this.walletBalance : 0;
      this.balance = this.orderCurrency === "USDT" || this.orderCurrency === "USDTE" && this.usdtBalance ? this.usdtBalance : 0;
    },
    async getGeneticData() {
      this.geneticData = await queryGeneticDataById(this.api, this.geneticOrderDetail.geneticDataId);
    },
    async setPaid() {
      this.isLoading = true;
      await setGeneticAnalysisPaid(this.api, this.wallet, this.orderId);
    },
    async getGeneticAnalysisOrderDetail() {
      this.geneticOrderDetail = await queryGeneticAnalysisOrderById(this.api, this.orderId);
      this.createdDate = this.formatDate(this.geneticOrderDetail.createdAt);
      this.orderStatus = this.geneticOrderDetail.status;
      this.orderPrice = this.formatBalance(this.geneticOrderDetail.prices[0].value, formatUSDTE(this.geneticOrderDetail.currency));
      this.qcPrice = this.formatBalance(this.geneticOrderDetail.additionalPrices[0].value, formatUSDTE(this.geneticOrderDetail.currency));
      this.totalPrice = (Number(this.qcPrice) + Number(this.orderPrice)).toLocaleString("en-US");
      this.orderCurrency = this.geneticOrderDetail.currency;
      await this.getRate();
      const orderPriceInUsd = this.formatPriceInUsd(this.geneticOrderDetail.prices[0].value);
      const qcPriceInUsd = this.formatPriceInUsd(this.geneticOrderDetail.additionalPrices[0].value);
      this.totalPriceInUsd = (Number(orderPriceInUsd) + Number(qcPriceInUsd)).toFixed(4);
      this.trackingId = this.geneticOrderDetail.geneticAnalysisTrackingId;
    },
    formatBalance(val, currency) {
      let unit;
      currency === "USDT" || currency === "USDT.e" ? unit = "mwei" : unit = "ether";
      const formatedBalance = Web3.utils.fromWei(String(val.replaceAll(",", "")), unit);
      return Number(formatedBalance).toLocaleString("en-US");
    },
    formatPriceInUsd(val) {
      const priceInUsd = Number(val.replaceAll(",", ""));
      const formatedBalance = Web3.utils.fromWei(String(priceInUsd), "mwei");
      return Number(formatedBalance * this.rate.conversion).toFixed(4);
    },
    formatDate(date) {
      const formattedDate = new Date(parseInt(date.replace(/,/g, ""))).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric"
      });
      return formattedDate;
    },
    async getRate() {
      var _this$orderCurrency, _this$orderCurrency2;
      if (((_this$orderCurrency = this.orderCurrency) === null || _this$orderCurrency === void 0 ? void 0 : _this$orderCurrency.toUpperCase()) === "DBIO") {
        const rate = await getConversion();
        this.rate = rate.dbioToUsd;
        return;
      }
      if (((_this$orderCurrency2 = this.orderCurrency) === null || _this$orderCurrency2 === void 0 ? void 0 : _this$orderCurrency2.toUpperCase()) === "USDTE") {
        this.rate = {
          conversion: 1.000
        };
        return;
      }
      try {
        this.rate = await getConversion(this.orderCurrency, "USD");
      } catch (error) {
        this.rate = {
          conversion: 1.000
        };
      }
    },
    async cancelOrder() {
      this.isCancelling = true;
      try {
        await cancelGeneticAnalysisOrder(this.api, this.wallet, this.orderId);
      } catch (e) {
        this.error = await errorHandler(e.message);
        this.isCancelling = false;
        this.showCancelDialog = false;
      }
    },
    async getCancelOrderFee() {
      const txWeight = await cancelGeneticAnalysisOrderFee(this.api, this.wallet, this.orderId);
      this.txWeight = Web3.utils.fromWei(String(txWeight.partialFee), "ether");
      this.showCancelDialog = true;
    },
    async getAnalysisStatus() {
      const details = await queryGeneticAnalysisByGeneticAnalysisTrackingId(this.api, this.trackingId);
      if (details.status !== "Registered") {
        this.isRegistered = false;
      }
    },
    toDashboard() {
      this.$router.push({
        name: "customer-dashboard"
      });
    },
    toPaymentHistory() {
      this.$router.push({
        name: "customer-payment-history"
      });
    }
  }
};